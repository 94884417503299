<template>
<b-container fluid>
  <b-container>
    <!-- logo -->
    <div class="logo"><img alt="Logo" src="../assets/logo_2.png" /></div>
    <br />
    <h1>3PETERS</h1>
    <h3>Digital Agency</h3>
    <br /><br /><br /><br />

    <!-- rolunk -->
    <h1 class="focim1">
      Rólunk
      <h1 class="focim2">Rólunk</h1>
    </h1>
    <rolunk></rolunk>

    <!-- galeria -->
    <br />
    <br />
    <br />
    <h1 class="focim1">
      Galéria
      <h1 class="focim2">Galéria</h1>
    </h1>
    <b-row align-v="center" align-h="around">
      <job-card
        class="munka"
        v-for="munka in munkak"
        :key="munka.id"
        :name="munka.name"
        :imglink="munka.imglink"
        :leiras="munka.leiras"
        :sitelink="munka.sitelink"
        :isgalery="munka.isgalery"
      ></job-card>
    </b-row>

    <!-- profil -->
    <br /><br /><br />
    <h1 class="focim1">
      Profil
      <h1 class="focim2">Profil</h1>
    </h1>

    <!-- ikonok -->
    <profil></profil>

  </b-container>

  <!-- Footer -->

  <b-container fluid>
      <b-row class="footer">
        <b-col>
          <b-img src="../assets/base/monologo.png" class="monologo"> </b-img>
        </b-col>
        <b-col class="ctr">
        <b-button size="lg" variant="danger" href="mailto:hello@3peters.hu" class="mb-3 mt-2">hello@3peters.hu</b-button><br />
06-30-481-86-19
</b-col>
      </b-row>
      <div class="impress">Szántó Péter © 2020</div>
    </b-container>
</b-container>
</template>

<script>
// @ is an alias to /src

import rolunk from '@/components/rolunk.vue'
import JobCard from '@/components/JobCard.vue'
import profil from '@/components/profil.vue'

export default {
  name: 'Home',
  components: {
    'job-card': JobCard,
    rolunk: rolunk,
    profil: profil
  },
  mounted () {
    this.fetchData()
  },
  data () {
    return {
      munkak: []
    }
  },
  methods: {
    async fetchData () {
      const res = await fetch('munkak.json')
      const val = await res.json()
      this.munkak = val
      console.log(val)
    }
  }
}
</script>

<style>

h1 {
  font-family: "Montserrat Alternates", sans-serif;
  color: #b3b3b3;
  font-weight: 400;
  font-size: 5rem !important;
  line-height: 0.7 !important;
}

h3 {
  font-family: "Montserrat Alternates", sans-serif;
  color: #b3b3b3;
  font-weight: 300;
  font-size: 3rem !important;
}

p {
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
}

.logo {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.logo img {
  max-width: 250px;
  height: auto;
}

.focim1 {
  margin-top: 2rem;
  font-size: 4rem !important;
  color: black;
  text-align: left;
  font-weight: 400;
}

.focim2 {
  font-size: 7rem !important;
  color: #f2f2f2;
  text-align: left;
  text-indent: 7%;
  font-weight: 900;
  position: relative;
  top: -5rem;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -40;
}

.munka {
  margin-bottom: 30px;
}

.iconcard {
  border: none !important;
}

.card-body {
  background-color: rgb(252, 252, 252);
  color: rgb(51, 51, 51);
}

.card-img-left {
  max-width: 100px;
  height: auto;
  padding: 10px;
}

.footer {
     text-align: left;
  margin-top: 10%;
  height:15em;
  background-color: rgb(190, 190, 190);
  position: relative;
  margin-right: -30px !important;
  margin-left: -30px !important;
}

.monologo {
max-width: 340px;
height: auto;
 position: absolute;
 bottom:-2em;
 left:15%;
 z-index: 20;
}

.ctr{
  padding-top:2%;
  margin-right: 1%;
  text-align: right;
  font-family: "Montserrat Alternates", sans-serif;
  color: #2c2c2c;
  font-weight: 600;
  font-style: italic;
  font-size: 3rem !important;
  z-index: 30 !important;
  position: relative;
}

.impress {
  padding-top: 15px; ;
  height: 3em;
   background-color: rgb(61, 61, 61);
   margin-right: -30px;
   margin-left: -30px;
   color: whitesmoke;
   position: relative;
   z-index: 50;
}

@media (max-width: 870px){
.ctr{
 font-size: 1.5rem !important;
}
}

@media (max-width: 540px){
h1 {
  font-size: 3rem !important;
  line-height: 0.7 !important;
}

h3 {
  font-weight: 300;
  font-size: 1rem !important;
}
}
</style>
