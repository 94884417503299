<template>

<b-container class="d-flex justify-content-left">
      <b-row>
        <b-col md>
          <p>
            A vállalkozások, cégek életében egyre fontosabb szerepe van a
            digitális megoldásoknak és az online jelenlétnek. Amikor egy
            szervezet szeretne ezen a téren előre lépni sokféle forma és
            megoldás közül választhat, mi abban próbálunk segíteni, hogy ez a
            választás a lehető legjobban sikerüljön.
          </p>
          <p>
            A kisvállalatoktól a nagyobb cégekig és szervezetekig sokféle
            partnerrel dolgoztunk már, sokféle feladaton, ami segít abban, hogy
            fel tudjuk mérni, milyen megoldások jöhetnek szóba egy-egy konkrét
            igény esetén. Az elkészült weboldalakra és egyéb megoldásokra hosszú
            távú garanciát tudunk vállalni, de a folyamatos üzemeltetés szintén
            lehetséges opció.
          </p>
        </b-col>
        <b-col md
          ><p>
            Elsősorban a KKV szektor szereplői számára szeretnénk a nagy
            ügynökségek komplex szolgáltatásait elérhető áron szállítani,
            arculattervezéstől a webes és közösségi médiában történő
            megjelenésen át, akár a napi ügymenet során felhasználható
            digitalizálási megoldások bevezetésééig (pl. feladatkezelés,
            dokumentumkezelés, OCR megoldások, stb).
          </p>
          <p>
            Az ehhez szükséges további külső szereplőkkel történő
            kapcsolattartás területén szintén több éves tapasztalattal
            rendelkezünk (nyomda, webhosting stb..).
          </p>
          <p>
            A digitális kultúra színvonalának emelését kiemelt feladatnak
            tekintjük minden projektünk során.
          </p>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {}
</script>

<style lang="stylus" scoped>
</style>
