<template>
  <div v-if="isgalery==0">
    <b-col>
      <b-card
        :title="name"
        :img-src= "require('../assets/munkak/'+imglink)"
        alt="Job-info"
        img-top
        tags="article"
        style="max-width: 30rem"
        class="mb-2"
      >
      <b-card-text>{{leiras}}</b-card-text>
      <a v-bind:href="sitelink" class="stretched-link" target="_blank"></a>
      </b-card>
    </b-col>
  </div>
</template>

<script>
export default {
  props: ['name', 'imglink', 'leiras', 'sitelink', 'isgalery']
}
</script>

<style lang="scss" scoped>
</style>
